<template>
  <Modal v-model="inventoryTransactionDetailsModal" :title="$t('key1000535')" width="1200" :transfer="false"
    :mask-closable="false" @on-visible-change="inventoryTransactionDetailsChange">
    <Form :model="pageParams" :label-width="labelWidth" v-if="systemType === 'supplier'" @submit.native.prevent>
      <Row type="flex" :gutter="gutterItem">
        <Col :xxl="threeItemCol" :xl="threeItemCol" :lg="threeItemCol" :md="threeItemCol">
          <Form-item :label="$t('key1000536')">
            <local-tabButton :tabButtonList="inventoryList" @selectStatus="inventoryBtn"></local-tabButton>
          </Form-item>
        </Col>
        <Col :xxl="twoItemCol" :xl="twoItemCol" :lg="twoItemCol" :md="twoItemCol">
          <Form-item :label="$t('key1000537')">
            <div style="display: flex; align-items: center;">
              <DatePicker type="date"
                format="yyyy-MM-dd"
                transfer
                :placeholder="$t('key1000538')"
                v-model="pageParams.expectedStartTime"
                style="width: 170px">
              </DatePicker>
              <span style="margin: 0 20px; display: inline-block;">{{ $t('key1000540') }}</span>
              <DatePicker type="date"
                format="yyyy-MM-dd"
                transfer
                :placeholder="$t('key1000539')"
                v-model="pageParams.expectedEndTime"
                style="width: 170px">
              </DatePicker>
            </div>
          </Form-item>
        </Col>
        <Col span="4">
          <Button type="primary" class="mr15" @click="inventDtlSearch" icon="ios-search">{{ $t('key1000269') }}</Button>
          <Button style="margin-left: 15px;" @click="exportBtn">{{ $t('key1000541') }}</Button>
        </Col>
      </Row>
    </Form>
    <!--列表区域-->
    <div class="table_box">
      <Table
        highlight-row
        border
        max-height="520"
        :loading="tableLoading"
        :columns="tableColumns"
        :data="tableData">
      </Table>
      <Page
        v-if="!tableLoading"
        :total="total"
        @on-change="changePage"
        show-total
        :page-size="pageParams.pageSize"
        show-elevator
        :current="pageParams.pageNum"
        show-sizer
        @on-page-size-change="changePageSize"
        placement="top"
        :page-size-opts="pageArray">
      </Page>
    </div>
    <template #footer>
      <Button type="primary" @click="inventoryTransactionDetailsModal = false">{{ $t('key1000204') }}</Button>
    </template>
  </Modal>
</template>

<script>
import { alias689d3582fa8c4a7891f9d6ea8bcd03a9 } from '@/customFolder/customVueAlias.js';

import api from '@/api/';
import Mixin from '@/components/mixin/common_mixin';
import {getUserNameByUserId, pageJump} from "@/utils/common";

export default {
  name: "inventoryTransactionDetailsModal",
  mixins: [Mixin],
  props: {
    systemType: { // 库存明细的类型  默认为供应商
      type: String,
      default: 'supplier'
    }
  },
  data() {
    return {
      inventoryTransactionDetailsModal: false,
      pageParamsStatus: false,
      pageParams: {
        expectedStartTime: null,
        expectedEndTime: null,
        changedNumType: null,
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      inventoryList: [
        {selected: true, value: null, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000095')},
        {selected: false, value: 1, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000542')},
        {selected: false, value: 2, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000543')}
      ],
      tableColumns: [],
      tableData: [],
      productGoodsId: null,
      warehouseId: null,
      ymsInventoryId: null
    }
  },
  methods: {
    // 初始化接口数据
    initFun(data) {
      let v = this;
      v.productGoodsId = data.productGoodsId || null;
      v.warehouseId = data.warehouseId || null;
      v.ymsInventoryId = data.ymsInventoryId || null;
      (async () => {
        if (v.systemType === 'yms') {
          await this.getUserName();
        }
        this.inventDtlSearch()
      })();
      v.handleColumns(v.systemType);
      v.inventoryTransactionDetailsModal = true;
    },
    // 处理列表字段
    handleColumns(systemType) {
      let v = this;
      if (systemType === 'yms') {
        v.tableColumns = [
          {
            type: 'index',
            title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000270'),
            width: 70,
            align: 'center'
          },
          {
            title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000544'),
            key: 'logType',
            align: 'center',
            width: 100,
            render: (h, params) => {
              let logType = params.row.type;
              let transactionTypeList = [
                {logType: 1, name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000413')},
                {logType: 2, name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000545')},
                {logType: 3, name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000546')},
                {logType: 4, name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000547')},
                {logType: 5, name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000548')},
                {logType: 6, name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000549')},
                {logType: 7, name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000550')},
                {logType: 8, name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000551')}
              ];
              let arr = transactionTypeList.filter((item) => item.logType === logType);
              if (arr && arr.length) {
                return h('span', arr[0].name);
              }
            }
          },
          {
            title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000542'),
            key: 'changedNum',
            align: 'center',
            minWidth: 130,
            render(h, params) {
              return params.row.originalAvailableNumber - params.row.curAvailableNumber <= 0
                ? h('span', {
                  style: {
                    color: 'green'
                  }
                }, '+' + (params.row.curAvailableNumber - params.row.originalAvailableNumber))
                : h('span', {
                  style: {
                    color: 'red'
                  }
                }, '-' + (params.row.originalAvailableNumber - params.row.curAvailableNumber));
            }
          },
          {
            title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000552'),
            key: 'curAvailableNumber',
            minWidth: 130,
            align: 'center'
          },
          {
            title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000553'),
            key: 'original_allotted_number',
            minWidth: 140,
            align: 'center',
            render(h, params) {
              return params.row.originalAllottedNumber - params.row.curAllottedNumber <= 0
                ? h('span', {
                  style: {
                    color: 'green'
                  }
                }, '+' + (params.row.curAllottedNumber - params.row.originalAllottedNumber))
                : h('span', {
                  style: {
                    color: 'red'
                  }
                }, '-' + (params.row.originalAllottedNumber - params.row.curAllottedNumber));
            }
          },
          {
            title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000554'),
            key: 'curAllottedNumber',
            minWidth: 130,
            align: 'center'
          },
          {
            title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000555'),
            key: 'relatedBusinessNo',
            minWidth: 160,
            align: 'center'
          },
          {
            title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000220'),
            key: 'updatedTime',
            width: 170,
            align: 'center'
          },
          {
            title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000221'),
            width: 110,
            key: 'updatedBy',
            align: 'center',
            render(h, params) {
              return h('div', getUserNameByUserId(params.row.updatedBy, v));
            }
          }
        ];
      } else if (systemType === 'supplier') {
        v.tableColumns = [
          {
            type: 'index',
            title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000270'),
            width: 70,
            align: 'center'
          },
          {
            title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000252'),
            key: 'warehouseName',
            width: 100,
            align: 'center'
          },
          {
            title: 'SKU',
            key: 'goodsSku',
            width: 100,
            align: 'center'
          },
          {
            title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000192'),
            key: 'goodsCnDesc',
            width: 120,
            align: 'center'
          },
          {
            title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000544'),
            key: 'logType',
            align: 'center',
            width: 120,
            render: (h, params) => {
              let logType = params.row.logType;
              let text = '';
              let transactionTypeList = [
                {logType: 'S0', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000276')},
                {logType: 'I0', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000556')},
                {logType: 'I1', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000413')},
                {logType: 'I2', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000557')},
                {logType: 'I3', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000558')},
                {logType: 'I4', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000559')},
                {logType: 'I5', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000560')},
                {logType: 'I6', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000561')},
                {logType: 'I7', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000562')},
                {logType: 'I8', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000563')},
                {logType: 'I9', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000564')},
                {logType: 'O0', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000565')},
                {logType: 'CC', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000566')},
                {logType: 'O2', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000567')},
                {logType: 'IA', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000568')},
                {logType: 'R1', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000569')},
                {logType: 'R3', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000570')},
                {logType: 'R4', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000547')},
                {logType: 'R5', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000571')},
                {logType: 'R6', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000572')}
              ];
              transactionTypeList.map((item) => {
                if (item.logType === logType) {
                  text = item.name;
                }
              });
              return h('span', text);
            }
          },
          {
            title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000542'),
            key: 'changedNum',
            align: 'center',
            minWidth: 130,
            render(h, params) {
              return params.row.changedType === '+'
                ? h('span', {
                  style: {
                    color: 'green'
                  }
                }, params.row.changedType + params.row.changedNum)
                : h('span', {
                  style: {
                    color: 'red'
                  }
                }, params.row.changedType + params.row.changedNum);
            }
          },
          {
            title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000552'),
            key: 'remainderNum',
            minWidth: 130,
            align: 'center'
          },
          {
            title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000553'),
            key: 'allottedChangedNum',
            minWidth: 130,
            align: 'center',
            render(h, params) {
              return params.row.allottedChangedNum > 0
                ? h('span', {
                  style: {
                    color: 'green'
                  }
                }, '+' + params.row.allottedChangedNum)
                : params.row.allottedChangedNum < 0
                  ? h('span', {
                    style: {
                      color: 'red'
                    }
                  }, params.row.allottedChangedNum)
                  : h('span', params.row.allottedChangedNum);
            }
          },
          {
            title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000554'),
            key: 'allottedRemainderNum',
            minWidth: 130,
            align: 'center'
          },
          {
            title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000555'),
            key: 'relatedBusinessNo',
            minWidth: 150,
            align: 'center'
          },
          {
            title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000220'),
            key: 'updatedTime',
            width: 170,
            align: 'center'
          },
          {
            title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000221'),
            minWidth: 130,
            key: 'updatedBy',
            align: 'center'
          }
        ];
      }
    },
    // 选取库存变化类型
    inventoryBtn(index, value) {
      let v = this;
      v.pageParams.changedNumType = value;
      v.inventoryList.forEach((n, i) => {
        n.selected = i === index;
      });
    },
    // 查询功能
    searchData() {
      let v = this;
      v.tableData = [];
      let url = '';
      let paramsObj = {
        pageNum: this.pageParams.pageNum,
        pageSize: this.pageParams.pageSize
      };
      if (v.systemType === 'yms') {
        if (v.system === 'yms') {
          url = api.post_ymsInventoryChangeLog_query;
        } else {
          url = api.post_wmsInventoryLog_queryYmsInventoryChangeLog;
        }
        paramsObj.ymsInventoryId = v.ymsInventoryId;
      } else if (v.systemType === 'supplier') {
        url = api.post_wmsInventoryLog_list;
        paramsObj.changedNumType = v.pageParams.changedNumType;
        paramsObj.productGoodsId = v.productGoodsId;
        paramsObj.warehouseId = v.warehouseId;
        // 开始时间
        if (v.pageParams.expectedStartTime) {
          paramsObj.updatedTimeStart = v.$uDate.getDateTime(v.pageParams.expectedStartTime, 'fulltime');
        }
        // 结束时间
        if (v.pageParams.expectedEndTime) {
          paramsObj.updatedTimeEnd = v.$uDate.getDateTime(v.pageParams.expectedEndTime) + ' 23:59:59';
        }
      }
      v.tableLoading = true;
      v.axios.post(url, paramsObj).then(res => {
        v.tableLoading = false;
        if (res.data.code === 0) {
          let data = res.data.datas;
          if (data) {
            let arr = data.list || [];
            v.tableData = this.processTimeData(arr, 'updatedTime');
            v.total = data.total;
          }
        } else {
          this.$Message.warning(alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000573'));
          return false;
        }
      });
    },
    // 页面查询
    inventDtlSearch() {
      this.pageParams.pageNum = 1;
      this.searchData();
    },
    // 导出按钮
    exportBtn() {
      let v = this;
      let paramsObj = {
        productGoodsId: v.productGoodsId,
        warehouseId: v.warehouseId,
        changedNumType: v.pageParams.changedNumType
      };
      if (v.pageParams.expectedStartTime) {
        paramsObj.updatedTimeStart = v.$uDate.getDateTime(v.pageParams.expectedStartTime, 'fulltime');
      } // 开始时间
      if (v.pageParams.expectedEndTime) {
        paramsObj.updatedTimeEnd = v.$uDate.getDateTime(v.pageParams.expectedEndTime) + ' 23:59:59';
      }
      v.axios.post(api.post_wmsInventoryLog_export, paramsObj).then(res => {
        if (res.data.code === 0) {
          let taskNumber = res.data.datas;
          setTimeout(() => {
            pageJump('/supplier.html#/exportTask?operateCode=' + taskNumber, '/yms-supplier-service', '_blank');
          }, 30);
        }
      });
    },
    // 监听弹窗
    inventoryTransactionDetailsChange(value) {
      if (!value) {
        if (this.systemType === 'supplier') {
          this.inventoryBtn(0, null);
          this.productGoodsId = null;
          this.warehouseId = null;
          this.ymsInventoryId = null;
          this.pageParams.expectedStartTime = null;
          this.pageParams.expectedEndTime = null;
          this.pageParams.pageNum = 1;
          this.pageParams.pageSize = 10;
          this.total = 0;
        }
      }
    }
  },
  watch: {
    pageParamsStatus(value) {
      let v = this;
      if (value) {
        v.searchData();
        v.pageParamsStatus = false;
      }
    }
  }
}
</script>

<style scoped>

</style>