<template>
  <Select
    v-model.trim="selectValue"
    transfer
    filterable
    clearable
    :remote-method="queryChange"
    :loading="classificationLoading"
    @on-clear="clearChange"
    v-select-more="selectMore">
    <Option v-for="item in classificationList" :key="item.ymsProductCategoryId" :value="item.ymsProductCategoryId">{{ item.title }}</Option>
  </Select>
</template>

<script>
import Mixin from '@/components/mixin/common_mixin';
import api from '@/api/';

export default {
  name: 'classificationSelect',
  mixins: [Mixin],
  data() {
    let v = this;
    return {
      selectValue: null,
      pageNo: 1, // 当前页数
      pageSize: 50, // 每页条数
      totalCount: 0, // 总条数（假设接口提供总条数的返回）
      classificationLoading: false,
      productCategoryData: [], // 缓存当前的数据
      classificationList: [],
      classifyRawData: []
    }
  },
  methods: {
    // 初始化数据
    initClassificationData(data, ymsProductCategoryId) {
      this.totalCount = data.length;
      this.classifyRawData = data;
      this.classificationList = this.getNextPageData(); // 获取第一页数据
      this.productCategoryData = JSON.parse(JSON.stringify(this.classificationList));
      if (ymsProductCategoryId) {
        if (this.classifyRawData.length > 0) {
          let list = this.classifyRawData.filter((item) => {
            return item.ymsProductCategoryId === ymsProductCategoryId;
          });
          this.selectValue = ymsProductCategoryId;
          let newList = [...this.classificationList, ...list];
          this.classificationList = this.uniqueFunc(newList, 'ymsProductCategoryId');
          localStorage.removeItem('seleteYmsProductCategoryId');
        }
      }
    },
    // 分页获取数据，默认50条一页
    getNextPageData() {
      const startIndex = (this.pageNo - 1) * this.pageSize;
      const endIndex = Math.min(startIndex + this.pageSize, this.totalCount);
      this.pageNo++;
      return this.classifyRawData.slice(startIndex, endIndex);
    },
    // 滚动到底部加载更多数据
    selectMore() {
      if (this.classificationList.length <= this.totalCount) {
        const nextPageData = this.getNextPageData();
        let newList = [...this.classificationList, ...nextPageData]; // 将下一页数据添加到数组中
        this.classificationList = this.uniqueFunc(newList, 'ymsProductCategoryId');
        this.productCategoryData = JSON.parse(JSON.stringify(this.classificationList));
      } else {
        console.log('已加载完所有数据');
      }
    },
    // 关键词搜索
    queryChange(query) {
      let v = this;
      if (query) {
        v.classificationLoading = true;
        setTimeout(() => {
          let list = v.classifyRawData.filter(item => item.title.toLowerCase().indexOf(query.toLowerCase()) > -1);
          v.classificationLoading = false;
          let newList = [...v.classificationList, ...list];
          v.classificationList = v.uniqueFunc(newList, 'ymsProductCategoryId');
        }, 1000);
      } else {
        setTimeout(() => {
          v.classificationList = JSON.parse(JSON.stringify(v.productCategoryData));
        }, 1000)
      }
    },
    // 清空数据
    clearChange() {
      let v = this;
      v.classificationList = [];
      v.classificationLoading = true;
      v.pageNo = 1;
      setTimeout(() => {
        v.classificationLoading = false;
        v.classificationList = v.getNextPageData();
        v.$forceUpdate();
      }, 1500);
    },
    // 重置
    reset() {
      let v = this;
      v.classificationList = [];
      v.selectValue = null;
      v.pageNo = 1;
      v.$nextTick(() => {
        v.classificationList = v.getNextPageData();
        v.$forceUpdate();
      });
    }
  },
  watch: {
    selectValue: {
      handler(value) {
        this.$emit('selectValue', value);
      },
      deep: true
    }
  }
};
</script>

<style lang="less">

</style>
